import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Container, Grid, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { PATH_GUEST } from 'src/routes/paths';
import { MAIN_LOGO } from 'src/config';
import useAuth from 'src/hooks/useAuth';

import Iconify from 'src/components/base/Iconify';
import AddressForm from 'src/components/signup/AddressForm';
import Page from 'src/components/base/Page';
import SignupStepper from 'src/components/signup/SignupStepper';

// ----------------------------------------------------------------------

export default function SignUp() {

    const { register } = useAuth();
    const navigate = useNavigate();

    const [loginError, setLoginError] = useState<string | null>(null);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [confirmEmail, setConfirmEmail] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

    const [signupComplete, setSignupComplete] = useState<boolean>(false);
    const [awaitingResponse, setAwaitingResponse] = useState<boolean>(false);

    useEffect(() => {
        if (
            (firstName.length > 0) ||
            (lastName.length > 0) ||
            (email.length > 0) ||
            (birthday.length > 0) ||
            awaitingResponse
        ) {
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [firstName, lastName, email, birthday, awaitingResponse]);

    const saveChanges = async () => {
        setAwaitingResponse(true);
        const getFormData = () => {
            const formData = new FormData();

            formData.append('first_name', firstName);
            formData.append('last_name', lastName);
            formData.append('email', email);
            formData.append('birthday', birthday);
            formData.append('password', password);
            return formData;
        };
        try {
            const res = await register(getFormData());
            console.log('res', res);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (res.success) {
                setSignupComplete(true);
            } else {
                setLoginError(res.detail || '');
            }
            setAwaitingResponse(false);
        } catch (error) {
            let message = null;
            if(JSON.parse(error).detail) {
                message = JSON.parse(error).detail;
            }
            setLoginError(message);
        }
    };

    return (
        <Page title="Sign Up">
            <Container maxWidth="sm" sx={{ py: 2 }}>
                <Stack sx={{ justifyContent: 'center', alignItems: 'center', my: 2 }} direction="row" spacing={1}>
                    <img
                        src={MAIN_LOGO}
                        alt="IS Logo"
                        onClick={() => { navigate(PATH_GUEST.login) }}
                        style={{ cursor: 'pointer' }}
                    />
                </Stack>
                <Stack spacing={1} width={'100%'} alignItems={'center'}>
                    <SignupStepper activeStep={signupComplete ? 1 : 0} />
                        {signupComplete ?
                            <Stack spacing={2} justifyContent={'center'} sx={{ height: '50vh' }}>
                                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                                    <Iconify
                                        icon={'line-md:email-twotone'}
                                        width={80}
                                        height={80}
                                    />
                                </Stack>
                                <Typography variant={'body1'} paragraph textAlign={'center'}>
                                    Please check your email for the verification message we've sent to you.
                                </Typography>
                            </Stack> :
                            <>
                                {loginError && <Alert severity="error">{loginError}</Alert>}
                                <AddressForm
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    email={email}
                                    setEmail={setEmail}
                                    confirmEmail={confirmEmail}
                                    setConfirmEmail={setConfirmEmail}
                                    birthday={birthday}
                                    setBirthday={setBirthday}
                                    password={password}
                                    setPassword={setPassword}
                                    confirmPassword={confirmPassword}
                                    setConfirmPassword={setConfirmPassword}
                                    setButtonDisabled={setButtonDisabled}
                                />
                                <Grid
                                    container
                                    spacing={3}
                                    justifyContent={'center'}
                                >
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            color="primary"
                                            loading={awaitingResponse}
                                            disabled={buttonDisabled}
                                            onClick={saveChanges}
                                            fullWidth
                                            variant='contained'
                                        >
                                            Sign Up
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </>
                        }
                </Stack>
            </Container>
        </Page>
    );
}